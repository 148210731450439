<template>
  <button
    :disabled="!reported"
    :class="[classes]"
    @click.capture="$emit('onClickShowResult', reportType)"
  >
    <span class="whitespace-nowrap">{{ label }}</span>
    <PrimeIcon
      v-if="reported"
      icon="EYE"
      class="cursor-pointer p-3 transition group-hover:scale-110"
    />
    <PrimeIcon v-else icon="CLOCK" class="p-3 text-careos-yellow" />
  </button>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { PrimeIcon } from 'careos-vue-components';

defineEmits(['onClickShowResult']);
const props = defineProps<{
  reportType: 'chiral' | 'DoA' | 'PEth';
  label: string;
  reported: boolean;
}>();

const baseClasses = ['group flex gap-4 justify-between items-center'];
const classes = computed(() => [
  baseClasses,
  props.reported ? 'cursor-pointer hover:bg-careos-purple-light' : '',
]);
</script>
