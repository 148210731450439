<template>
  <div class="mx-auto flex h-full max-w-4xl items-center justify-center py-4">
    <div
      v-if="!pdf"
      class="flex w-full max-w-96 flex-col rounded-lg border bg-white p-6 shadow-md"
    >
      <FormKit
        type="form"
        :submit-label="t('donor_credentials.submitButton')"
        :submit-attrs="{
          outerClass: '!h-[25px] !my-12',
          inputClass: '!p-4 !rounded-md',
        }"
        @submit="submit"
      >
        <FormKit
          :label="t('donor_credentials.phoneNumber.label')"
          name="phoneNumber"
          validation="required|internationalPhoneNumber"
          :validation-messages="{
            internationalPhoneNumber: t(
              'donor_credentials.phoneNumber.invalidPhoneNumber',
            ),
          }"
          type="phoneNumberInput"
        />
        <FormKit
          :label="t('donor_credentials.password.label')"
          name="password"
          :help="t('donor_credentials.password.help')"
          type="text"
          validation="required"
        />
      </FormKit>
      <CareosErrorMessage
        v-if="isCredentialsInvalid"
        :message="t('donor_credentials.credentialsInvalid')"
      />
    </div>
    <PdfEmbed v-if="pdf" :pdf-source="pdf" />
  </div>
</template>
<script setup lang="ts">
import { api } from '@/api/client';
import PdfEmbed from 'careos-vue-components/src/components/PdfEmbed.vue';
import { ref } from 'vue';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import CareosErrorMessage from '@/components/CareosErrorMessage.vue';
import { DonorLoginCredentialsRequestDto } from '@careos/toxicology-types';

const { t } = useI18n();
const route = useRoute();
const pdf = ref<string>();

const isCredentialsInvalid = ref(false);

const submit = async ({
  phoneNumber,
  password,
}: DonorLoginCredentialsRequestDto['credentials']) => {
  isCredentialsInvalid.value = false;
  try {
    const requisitionId = route.params.requisitionId as string;

    const { data } = await api.getSignedRequisitionPdfByDonorCredentials(
      requisitionId,
      {
        credentials: {
          phoneNumber,
          password,
        },
      },
    );
    pdf.value = data;
  } catch {
    isCredentialsInvalid.value = true;
  }
};
</script>
