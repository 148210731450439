import { z } from 'zod';
import { IsoDateTimeSchema, RequisitionSchema } from '../fhir-entities';
import { createSchemaClass } from '../utils/create-schema-class';
import { BaseEventSchema } from './base-event';

export const RequisitionReceivedEventSchema = BaseEventSchema.extend({
  eventType: z.literal('RequisitionReceivedEvent'),
  eventData: z.object({
    transactionId: z.string().optional(),
    requisition: RequisitionSchema,
    receivedAt: IsoDateTimeSchema,
    /**
     * Encrypted donor password for access to CareOS application.
     * The password is encrypted using bcrypt hashing function.
     */
    donorPassword: z.string().optional(),
  }),
});

/**
 * A requisition has been received from an external system/organization.
 */
export class RequisitionReceivedEvent extends createSchemaClass(
  RequisitionReceivedEventSchema,
) {}
