import { z } from 'zod';
import { CodeableConceptSchema } from './codeable-concept.interface';
import { InterpretationCodableConceptSchema } from './interpretation-codable-concept.interface';
import { IsoDateTimeSchema } from './iso-date.type';
import { PeriodSchema } from './period.interface';
import { QuantitySchema } from './quantity.interface';
import { RangeSchema } from './range.interface';
import { RatioSchema } from './ratio.interface';
import { ReferenceRangeSchema } from './reference-range.interface';

const DataAbsentReasonSchema = z.enum([
  'unknown',
  'masked',
  'error',
  'not-performed',
  'unsupported',
]);

export const BaseComponentSchema = z.object({
  /**
   * Type of component observation (code / type)
   * Binding: LOINC Codes: https://www.hl7.org/fhir/valueset-observation-codes.html
   * Example: https://www.hl7.org/fhir/terminologies.html#example
   */
  code: CodeableConceptSchema,

  /**
   * Why the component result is missing
   */
  dataAbsentReason: z.optional(DataAbsentReasonSchema),

  /**
   * High, low, normal, etc
   */
  interpretation: z.array(InterpretationCodableConceptSchema).optional(),

  /**
   * Reference range that provides guidance on how to interpret the value.
   */
  referenceRange: ReferenceRangeSchema.array().optional(),
});

/**
 * Common fields for different variants of {@link Component}.
 */
export type BaseComponent = z.infer<typeof BaseComponentSchema>;

/**
 * Schema for {@link ValueQuantityComponentSchema}
 */
export const ValueQuantityComponentSchema = BaseComponentSchema.extend({
  /**
   * Quantative value of a component result.
   */
  valueQuantity: QuantitySchema,
});

/**
 * Schema for {@link ValueCodeableConceptComponentSchema}
 */
export const ValueCodeableConceptComponentSchema = BaseComponentSchema.extend({
  /**
   * CodeableConcept value of a component result.
   */
  valueCodeableConcept: CodeableConceptSchema,
});

/**
 * Schema for {@link ValueStringComponentSchema}
 */
export const ValueStringComponentSchema = BaseComponentSchema.extend({
  /**
   * String value of a component result.
   */
  valueString: z.string(),
});

/**
 * Schema for {@link ValueBooleanComponentSchema}
 */
export const ValueBooleanComponentSchema = BaseComponentSchema.extend({
  /**
   * Boolean value of a component result.
   */
  valueBoolean: z.boolean(),
});

/**
 * Schema for {@link ValueIntegerComponentSchema}
 */
export const ValueIntegerComponentSchema = BaseComponentSchema.extend({
  /**
   * Integer value of a component result.
   */
  valueInteger: z.number(),
});

/**
 * Schema for {@link ValueRangeComponentSchema}
 */
export const ValueRangeComponentSchema = BaseComponentSchema.extend({
  /**
   * Range value of a component result.
   */
  valueRange: RangeSchema,
});

/**
 * Schema for {@link ValueRatioComponentSchema}
 */
export const ValueRatioComponentSchema = BaseComponentSchema.extend({
  /**
   * Ratio value of a component result.
   */
  valueRatio: RatioSchema,
});

/**
 * Schema for {@link ValueDateTimeComponentSchema}
 */
export const ValueDateTimeComponentSchema = BaseComponentSchema.extend({
  /**
   * DateTime value of a component result.
   */
  valueDateTime: IsoDateTimeSchema,
});

/**
 * Schema for {@link ValuePeriodComponentSchema}
 */
export const ValuePeriodComponentSchema = BaseComponentSchema.extend({
  /**
   * Period value of a component result.
   */
  valuePeriod: PeriodSchema,
});

export type ComponentValue = string;

export const ComponentSchema = z.union([
  ValueQuantityComponentSchema,
  ValueCodeableConceptComponentSchema,
  ValueStringComponentSchema,
  ValueBooleanComponentSchema,
  ValueIntegerComponentSchema,
  ValueRangeComponentSchema,
  ValueRatioComponentSchema,
  ValuePeriodComponentSchema,
]);

/**
 * Component results.
 */
export type Component = z.infer<typeof ComponentSchema>;
